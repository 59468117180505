<template>
    <div>
        <div v-for="(list, groupIndex) in group" :key="groupIndex">
            <h2><span :style="{ color: 'red' }">{{list[0]}}</span> vs <span :style="{ color: 'green' }">{{list[1]}}<span></h2>
            <div class="parent">
                <div v-for="(item, index) in [list[0], list[1]]" :key="index">
                    <div>{{item}}</div>
                    <div v-html="diff(parse(item))"></div>
                </div>
                <div>
                    <div>vs</div>
                    <div v-html="diff(parse(list[0]), parse(list[1]))"></div>
                </div>
            </div>
            <div class="run">
                <div><button @click="run(list)">run</button></div>
                <pre><code>{{list[2]}}</code></pre>
            </div>
        </div>
    </div>
</template>

<script>
import { parse } from '@babel/parser';
import generator from '@babel/generator';
import * as jsondiffpatch from 'jsondiffpatch';

export default {
    props: ['base', 'data'],
    computed: {
        auto() {
            return parse(this.base);
        },
        group() {
            return this.data || [];
        }
    },
    methods: {
        parse(i) {
            return parse(i);
        },
        diff(m, n) {
            let left = m;
            let right = n;
            if (!n) {
                left = this.auto;
                right = m;
            }
            const delta = jsondiffpatch.diff(left, right)
            return jsondiffpatch.formatters.html.format(delta, right);
        },
        run(list) {
            const codePre = list[0];
            const ast = parse(codePre);
            const doit = new Function('ast', list[2]);
            doit(ast);
            const codePost = generator(ast).code;
            console.table([
                {
                    codePre,
                    codePost,
                    vs: list[1]
                }
            ]);
            console.log(ast);
        }
    }
};
</script>

<style lang="less" scoped>
.parent {
    display: flex;
    flex-direction: row;
}
.run {
    background: #efefef;
    border-radius: 4px;
    padding: 16px;
}
</style>
